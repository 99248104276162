import 'ol/ol.css';

import { useContext, useRef, useState } from 'react';

import PlayerContext from '../../playground/playerContext/PlayerContext';
import {
  useAlertsLayer,
  useCameraLayer,
  useGetNiraVector,
  useRoadsLayer,
  useSitesLayer,
  useVehiclesLayer,
} from '../hooks';
import { useGetLatestDate } from '../hooks/apiPikAlert/useGetLatestDate';
import useMap from '../hooks/useMap';
import useTooltipOverlay from '../hooks/useMapOverlay';
import { LayerSwitch } from './LayerSwitch';
import { alertModal } from './mapComponents/AlertModal';
import { vehicleModal } from './mapComponents/VehicleModal';
import MapStyle from './MapStyle';
import { useWeatherDataLayers } from './radar/useWeatherDataLayers';

const MapComponent = ({ project, layers, setLayers, mapStyleCard, alert, time, setSite }) => {
  const mapRef = useRef(null);

  const [nira, setNira] = useState({ enabled: true, color: '#FF0000', strokeWidth: 3 });
  const [alertFrequency, setAlertFrequency] = useState(60);
  const [mapStyle, setMapStyle] = useState('basic');

  const { data: latest } = useGetLatestDate();
  const contextValue = useContext(PlayerContext);

  const mapInstanceRef = useMap({ mapRef, mapStyle });

  const tooltipOverlayRef = useTooltipOverlay(mapInstanceRef, time);

  useGetNiraVector({ map: mapInstanceRef.current, setup: nira });
  useCameraLayer(mapInstanceRef.current, time, tooltipOverlayRef.current, latest, layers.cameras);
  useRoadsLayer(
    mapInstanceRef.current,
    alert,
    time,
    tooltipOverlayRef.current,
    layers.roads,
    setSite,
  );
  useSitesLayer(mapInstanceRef.current, time, latest, layers.sites);
  useWeatherDataLayers(project, mapInstanceRef.current, contextValue);
  useVehiclesLayer(
    mapInstanceRef.current,
    time,
    tooltipOverlayRef.current,
    latest,
    layers.vehicles,
    vehicleModal,
  );
  useAlertsLayer({
    mapInstanceRef,
    poolTime: alertFrequency,
    alertLayer: layers.alert,
    onFeatureClick: alertModal,
  });

  return (
    <div className="relative">
      <LayerSwitch
        setLayers={setLayers}
        layers={layers}
        niraSetup={nira}
        niraEdit={setNira}
        frequency={alertFrequency}
        setFrequency={setAlertFrequency}
      />
      <div ref={mapRef} className="w-full h-screen" />
      <MapStyle
        switchToOSM={() => setMapStyle('basic')}
        switchToSatellite={() => setMapStyle('satellite')}
      />
    </div>
  );
};

export default MapComponent;
